import { Box, Center, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import MyLoader from "../../../../component/loader/MyLoader";
import {
  Body,
  Cell,
  Footer,
  FooterCell,
  FooterRow,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import { FONTSIZES } from "../../../../theme/FontSize";
import formatedDateTime from "../../../../utils/formatedDateTime";
import { formattedCurrency } from "../../../../utils/formatedCurrency";
import emptyLotie from "../../../../assets/lotties/empty.json";
import Lottie from "react-lottie";
const Trans = ({ isLoading, data, theme, totalSales }) => {
  return (
    <Box p={1} borderWidth="1px" borderRadius="lg">
      {isLoading ? (
        <MyLoader />
      ) : data.nodes?.length > 0 ? (
        <Table
          layout={{ custom: true, horizontalScroll: true }}
          data={data}
          theme={theme}
        >
          {(tableList) => (
            <>
              <Header>
                <HeaderRow>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      No
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Transaction No
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Date
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Cashier
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Code
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Name
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Address
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Member
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Payment Method
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Source
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Delivery Type
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Item
                    </Text>
                  </HeaderCell>

                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Point
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Shipping Cost
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Tax
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Voucher Code
                    </Text>
                  </HeaderCell>

                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Voucher
                    </Text>
                  </HeaderCell>

                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Grand Total
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Payment
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Cash
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Change
                    </Text>
                  </HeaderCell>
                </HeaderRow>
              </Header>

              <Body>
                {tableList.map((item, index) => (
                  <Row key={item.id} item={item}>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {index + 1}
                      </Text>
                    </Cell>

                    <Cell>
                      <HStack>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.transaction_no}
                        </Text>
                      </HStack>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formatedDateTime(item.transaction_date)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item?.created_person?.person?.name}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.store.code}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.store.name}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.store.address}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.person?.name}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.payment_method?.name}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.source}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.delivery_type.toUpperCase()}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.sales_details_count} Item
                      </Text>
                    </Cell>

                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formattedCurrency(item.point)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.total)}
                      </Text>
                    </Cell>

                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.delivery_fee)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.tax)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.voucher_code ? item.voucher_code : "-"}
                        {item.discount_type === "percentage" && "(%)"}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.nominal_voucher)}
                      </Text>
                    </Cell>

                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.grand_total)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item?.payment_detail?.pay)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp.{" "}
                        {formattedCurrency(item?.payment_detail?.total_cash)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp.{" "}
                        {formattedCurrency(item?.payment_detail?.total_change)}
                      </Text>
                    </Cell>
                  </Row>
                ))}
              </Body>
              <Footer>
                <FooterRow>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>

                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      {formattedCurrency(totalSales?.sum_sales_point)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp {formattedCurrency(totalSales?.sum_sales_total)}
                    </Text>
                  </FooterCell>

                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp {formattedCurrency(totalSales?.sum_sales_delivery_fee)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp {formattedCurrency(totalSales?.sum_sales_tax)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp{" "}
                      {formattedCurrency(totalSales?.sum_sales_nominal_voucher)}
                    </Text>
                  </FooterCell>

                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp {formattedCurrency(totalSales?.sum_sales_grand_total)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp{" "}
                      {formattedCurrency(totalSales?.sum_sales_total_payment)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp {formattedCurrency(totalSales?.sum_sales_total_cash)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp {formattedCurrency(totalSales?.sum_sales_total_change)}
                    </Text>
                  </FooterCell>
                </FooterRow>
              </Footer>
            </>
          )}
        </Table>
      ) : (
        <Stack mt={5}>
          <Center>
            <Text fontFamily={"Semibold"}>Data Not Found</Text>
          </Center>
          <Lottie
            options={{
              animationData: emptyLotie,
            }}
            width={"20vw"}
          />
        </Stack>
      )}
    </Box>
  );
};

export default Trans;
