import { Box, Center, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import MyLoader from "../../../../component/loader/MyLoader";
import {
  Body,
  Cell,
  Footer,
  FooterCell,
  FooterRow,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import { FONTSIZES } from "../../../../theme/FontSize";
import formatedDate from "../../../../utils/formatedDate";
import { formattedCurrency } from "../../../../utils/formatedCurrency";
import Lottie from "react-lottie";
import emptyLotie from "../../../../assets/lotties/empty.json";
const Customer = ({ isLoading, data, theme, totalSales }) => {
  return (
    <Box p={1} borderWidth="1px" borderRadius="lg">
      {isLoading ? (
        <MyLoader />
      ) : data.nodes?.length > 0 ? (
        <Table
          layout={{ custom: true, horizontalScroll: true }}
          data={data}
          theme={theme}
        >
          {(tableList) => (
            <>
              <Header>
                <HeaderRow>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      No
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Member Code
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Member Name
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Date
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Email
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Phone
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Code
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Name
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Address
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Sales Count
                    </Text>
                  </HeaderCell>

                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Tax
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Shipping Cost
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Voucher
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Grand Total
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Payment
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Cash
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Change
                    </Text>
                  </HeaderCell>
                </HeaderRow>
              </Header>

              <Body>
                {tableList.map((item, index) => (
                  <Row key={item.id} item={item}>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {index + 1}
                      </Text>
                    </Cell>

                    <Cell>
                      <HStack>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.code}
                        </Text>
                      </HStack>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.name}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formatedDate(item.created_at)}
                      </Text>
                    </Cell>
                    <Cell>
                      <HStack>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.email}
                        </Text>
                      </HStack>
                    </Cell>
                    <Cell>
                      <HStack>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.phone_number}
                        </Text>
                      </HStack>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.store.code}
                      </Text>
                    </Cell>

                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.store.name}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.store.address}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.sales_count}
                      </Text>
                    </Cell>

                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.sales_sum_total)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.sales_sum_tax)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.sales_sum_delivery_fee)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.sales_sum_nominal_voucher)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp. {formattedCurrency(item.sales_sum_grand_total)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp.{" "}
                        {formattedCurrency(item.payment_header_sum_total_pay)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp.{" "}
                        {formattedCurrency(item.payment_header_sum_total_cash)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        Rp.{" "}
                        {formattedCurrency(
                          item.payment_header_sum_total_change
                        )}
                      </Text>
                    </Cell>
                  </Row>
                ))}
              </Body>
              <Footer>
                <FooterRow>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>

                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>

                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp. {formattedCurrency(totalSales.sum_sales_total)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp. {formattedCurrency(totalSales.sum_sales_tax)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp. {formattedCurrency(totalSales.sum_sales_delivery_fee)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp.{" "}
                      {formattedCurrency(totalSales.sum_sales_nominal_voucher)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp. {formattedCurrency(totalSales.sum_sales_grand_total)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp.{" "}
                      {formattedCurrency(totalSales.sum_sales_total_payment)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp. {formattedCurrency(totalSales.sum_sales_total_cash)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Rp. {formattedCurrency(totalSales.sum_sales_total_change)}
                    </Text>
                  </FooterCell>
                </FooterRow>
              </Footer>
            </>
          )}
        </Table>
      ) : (
        <Stack mt={5}>
          <Center>
            <Text fontFamily={"Semibold"}>Data Not Found</Text>
          </Center>
          <Lottie
            options={{
              animationData: emptyLotie,
            }}
            width={"20vw"}
          />
        </Stack>
      )}
    </Box>
  );
};

export default Customer;
