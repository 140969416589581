import { Box, Center, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import MyLoader from "../../../../component/loader/MyLoader";
import {
  Body,
  Cell,
  Footer,
  FooterCell,
  FooterRow,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import { FONTSIZES } from "../../../../theme/FontSize";
import formatedDateTime from "../../../../utils/formatedDateTime";
import { formattedCurrency } from "../../../../utils/formatedCurrency";
import emptyLotie from "../../../../assets/lotties/empty.json";
import Lottie from "react-lottie";
import formatedDate from "../../../../utils/formatedDate";
const Payment = ({ isLoading, data, theme, totalSales }) => {
  return (
    <Box p={1} borderWidth="1px" borderRadius="lg">
      {isLoading ? (
        <MyLoader />
      ) : data.nodes?.length > 0 ? (
        <Table
          layout={{ custom: true, horizontalScroll: true }}
          data={data}
          theme={theme}
        >
          {(tableList) => (
            <>
              <Header>
                <HeaderRow>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      No
                    </Text>
                  </HeaderCell>

                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Transaction Date
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Name
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Store Address
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Payment Method
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Nota
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Product
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Sales
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Payment
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Cash
                    </Text>
                  </HeaderCell>
                  <HeaderCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      Total Change
                    </Text>
                  </HeaderCell>
                </HeaderRow>
              </Header>

              <Body>
                {tableList.map((item, index) => (
                  <Row key={item.id} item={item}>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {index + 1}
                      </Text>
                    </Cell>

                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formatedDate(item.transaction_date)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.store}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.address}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.name}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.total_nota}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {item.total_product}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formattedCurrency(item.total_sales)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formattedCurrency(item.total_payment)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formattedCurrency(item.total_cash)}
                      </Text>
                    </Cell>
                    <Cell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Regular"}
                        color="black"
                      >
                        {formattedCurrency(item.total_change)}
                      </Text>
                    </Cell>
                  </Row>
                ))}
              </Body>
              <Footer>
                <FooterRow>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    ></Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      {" "}
                      {formattedCurrency(totalSales.total_nota)}
                    </Text>
                  </FooterCell>

                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      {formattedCurrency(totalSales.total_product)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      {" "}
                      {formattedCurrency(totalSales.total_sales)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      {formattedCurrency(totalSales.total_payment)}
                    </Text>
                  </FooterCell>

                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      {formattedCurrency(totalSales.total_cash)}
                    </Text>
                  </FooterCell>
                  <FooterCell>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"Bold"}
                      color={"black"}
                    >
                      {formattedCurrency(totalSales.total_change)}
                    </Text>
                  </FooterCell>
                </FooterRow>
              </Footer>
            </>
          )}
        </Table>
      ) : (
        <Stack mt={5}>
          <Center>
            <Text fontFamily={"Semibold"}>Data Not Found</Text>
          </Center>
          <Lottie
            options={{
              animationData: emptyLotie,
            }}
            width={"20vw"}
          />
        </Stack>
      )}
    </Box>
  );
};

export default Payment;
