import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as React from "react";
import ICFilter from "../../../assets/icons/ICFilter";

import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { AsyncSelect, Select } from "chakra-react-select";
import { format } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Calendar } from "lucide-react";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import MyIcon from "../../../component/myIcon/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import { baseURL, URL } from "../../../utils/appURL";
import formatedDate from "../../../utils/formatedDate";
import formatedDateTime from "../../../utils/formatedDateTime";
import { useHttp } from "../../../utils/http";
import Cashier from "./component/Cashier";
import Customer from "./component/Customer";
import Payment from "./component/Payment";
import Product from "./component/Product";
import Total from "./component/Total";
import Trans from "./component/Trans";
const dataType = [
  { id: "trans", title: "Transaction" },
  { id: "product", title: "Product" },
  { id: "customer", title: "Customer" },
  { id: "cashier", title: "Cashier" },
  { id: "payment_method", title: "Payment Method" },
  { id: "total", title: "Total" },
];
const ReportSale = ({ label }) => {
  const { get } = useHttp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [noTransaction, setnoTransaction] = useState("");
  const [data, setData] = useState({ nodes: [] });
  const [type, settype] = useState("");
  const [totalSales, settotalSales] = useState(0);
  const [filter, setfilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    storeId: "",
  });
  const [selectedCategorie, setselectedCategorie] = useState("");
  const [selectedCashier, setselectedCashier] = useState("");
  const [selectedSubCategory, setselectedSubCategory] = useState("");
  const [selectedCustomer, setselectedCustomer] = useState("");
  const [selectedStore, setselectedStore] = useState("");
  const [selectedProduct, setselectedProduct] = useState("");
  const [selectedPayment, setselectedPayment] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const WW = window.innerWidth;
  const [sizesColumn, setSizesColumn] = useState("200px");
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customProduct = useTheme({
    Table: `
        --data-table-library_grid-template-columns: ${
          WW < 770 ? "100px" : "100px"
        } ${sizesColumn} ${"300px"} ${sizesColumn}  ${sizesColumn} ${"300px"}  ${sizesColumn}  ${"300px"}  ${"600px"} ${"300px"}  ${sizesColumn} ${sizesColumn} ${"300px"}  ${sizesColumn} ${"300px"}  ${sizesColumn} ${sizesColumn}  ${"600px"} ${sizesColumn}  ${sizesColumn}  ${sizesColumn}   ${sizesColumn}  ${sizesColumn} ${sizesColumn} ;
      `,

    Row: `
        &:hover {
            background-color: #d2fbf2;
            cursor: pointer; /* Add this line to change cursor to pointer on hover */
          }`,
  });
  const costumCostumer = useTheme({
    Table: `
        --data-table-library_grid-template-columns: ${
          WW < 770 ? "100px" : "100px"
        } ${sizesColumn}  ${sizesColumn} ${"300px"}   ${sizesColumn}   ${sizesColumn} ${"250px"}  ${sizesColumn}   ${"400px"}  ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}    ;
      `,

    Row: `
        &:hover {
            background-color: #d2fbf2;
            cursor: pointer; /* Add this line to change cursor to pointer on hover */
          }`,
  });
  const customPaymentMethod = useTheme({
    Table: `
        --data-table-library_grid-template-columns: ${
          WW < 770 ? "100px" : "100px"
        } ${"300px"}  ${sizesColumn}   ${sizesColumn}  ${sizesColumn} ${sizesColumn}   ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn};
      `,

    Row: `
        &:hover {
            background-color: #d2fbf2;
            cursor: pointer; /* Add this line to change cursor to pointer on hover */
          }`,
  });
  const customTotal = useTheme({
    Table: `
        --data-table-library_grid-template-columns: ${
          WW < 770 ? "100px" : "100px"
        }  ${"300px"}   ${sizesColumn}  ${sizesColumn} ${sizesColumn}   ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn};
      `,

    Row: `
        &:hover {
            background-color: #d2fbf2;
            cursor: pointer; /* Add this line to change cursor to pointer on hover */
          }`,
  });
  const customTrans = useTheme({
    Table: `
        --data-table-library_grid-template-columns: ${
          WW < 770 ? "100px" : "100px"
        }
          ${sizesColumn}  ${"300px"}  ${sizesColumn}
          ${sizesColumn}  ${sizesColumn}  ${sizesColumn}
        ${sizesColumn}   ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn} ${sizesColumn} ${sizesColumn} ${sizesColumn}  ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}   ${sizesColumn} ;
      `,

    Row: `
        &:hover {
            background-color: #d2fbf2;
            cursor: pointer; /* Add this line to change cursor to pointer on hover */
          }`,
  });
  const [customTheme, setcustomTheme] = useState(customTrans);
  const theme = [chakraTheme, customTheme];
  const [isLoading, setisLoading] = useState(false);
  const fetchData = () => {
    const apiUrlNew = `${baseURL}/report/sales?transaction_no=${noTransaction}&store_id=${
      selectedStore ? selectedStore.id : ""
    }&category_id=${selectedCategorie ? selectedCategorie.id : ""}&cashier_id=${
      selectedCashier ? selectedCashier.id : ""
    }&sub_category_id=${
      selectedSubCategory ? selectedSubCategory.id : ""
    }&customer_id=${selectedCustomer ? selectedCustomer.id : ""}&product_id=${
      selectedProduct ? selectedProduct.id : ""
    }&payment_method_id=${
      selectedPayment ? selectedPayment.id : ""
    }&from_date=${format(filter.startDate, "yyyy-MM-dd")}&to_date=${format(
      filter.endDate,
      "yyyy-MM-dd"
    )}&type=${type.value}`;

    setisLoading(true);

    get({
      url: apiUrlNew,
      needAuth: true,
      showToast: false,
    })
      .then((response) => {
        const a = response.data.data.sales_list;
        settotalSales(response.data.data);

        const flattenedData = a.map(flattenObject);

        const keys = Object.keys(flattenedData[0] || {});

        const headerString = keys.join(",") + "\n";
        const rowString = flattenedData
          .map((d) => {
            const mappedData = keys.map((key) => {
              return d[key] || "";
            });

            return `${mappedData.join(",")}\n`;
          })
          .join("");

        setData({
          nodes: a,
        });
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };

  const fetchDataCashier = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListCashierURL +
        `?with_store=1&search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsCashier = (inputValue) => {
    return fetchDataCashier(inputValue);
  };
  const fetchDataProduct = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.product.data;
    });
    return allData;
  };
  const loadOptionsProduct = (inputValue) => {
    return fetchDataProduct(inputValue);
  };
  const fetchDataPayment = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListPaymentMethod + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsPayment = (inputValue) => {
    return fetchDataPayment(inputValue);
  };
  const fetchDataCategorie = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductCategoryURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsCategorie = (inputValue) => {
    return fetchDataCategorie(inputValue);
  };
  const fetchDataCustomer = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListCustomerURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsCustomer = (inputValue) => {
    return fetchDataCustomer(inputValue);
  };

  const fetchDataSubCategory = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductSubCategoryURL +
        `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsSubCategory = (inputValue) => {
    return fetchDataSubCategory(inputValue);
  };
  const flattenObject = (obj, prefix = "") =>
    Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        if (
          ![
            "created_at",
            "created_by",
            "updated_at",
            "updated_by",
            "deleted_at",
            "deleted_by",
          ].includes(k)
        ) {
          Object.assign(acc, flattenObject(obj[k], pre + k));
        }
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});

  const handleType = (e) => {
    setData({ nodes: [] });
    settype(e);
  };

  const exportToExcel = async () => {
    if (type.value === "trans") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;
      ws.getColumn(13).width = 30;
      ws.getColumn(14).width = 30;
      ws.getColumn(15).width = 30;
      ws.getColumn(16).width = 30;
      ws.getColumn(17).width = 30;
      ws.getColumn(18).width = 30;
      ws.getColumn(19).width = 30;
      ws.getColumn(20).width = 30;
      ws.getColumn(21).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 21; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction No";
      rowHeader.getCell(2).value = "Date";
      rowHeader.getCell(3).value = "Cashier";
      rowHeader.getCell(4).value = "Store Code";
      rowHeader.getCell(5).value = "Store Name";
      rowHeader.getCell(6).value = "Store Address";
      rowHeader.getCell(7).value = "Member";
      rowHeader.getCell(8).value = "Payment Method";
      rowHeader.getCell(9).value = "Source";
      rowHeader.getCell(10).value = "Delivery Type";
      rowHeader.getCell(11).value = "Item Count";
      rowHeader.getCell(12).value = "Point";
      rowHeader.getCell(13).value = "Total";
      rowHeader.getCell(14).value = "Shipping Cost";
      rowHeader.getCell(15).value = "Tax";
      rowHeader.getCell(16).value = "Voucher Code";
      rowHeader.getCell(17).value = "Voucher";
      rowHeader.getCell(18).value = "Grand Total";
      rowHeader.getCell(19).value = "Total Payment";
      rowHeader.getCell(20).value = "Total Cash";
      rowHeader.getCell(21).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].transaction_no;
        row.getCell(2).value = formatedDateTime(
          data.nodes[i - 1].transaction_date
        );
        row.getCell(3).value = data.nodes[i - 1].created_person.person.name;
        row.getCell(4).value = data.nodes[i - 1].store.code;
        row.getCell(5).value = data.nodes[i - 1].store.name;
        row.getCell(6).value = data.nodes[i - 1].store.address;
        row.getCell(7).value = data.nodes[i - 1].person
          ? data.nodes[i - 1].person.name
          : "";
        row.getCell(8).value = data.nodes[i - 1].payment_method.name;
        row.getCell(9).value = data.nodes[i - 1].source;
        row.getCell(10).value = data.nodes[i - 1].delivery_type.toUpperCase();
        row.getCell(11).value = data.nodes[i - 1].sales_details_count;
        row.getCell(12).value = data.nodes[i - 1].point;
        row.getCell(13).value = data.nodes[i - 1].total;
        row.getCell(14).value = data.nodes[i - 1].delivery_fee || 0;
        row.getCell(15).value = data.nodes[i - 1].tax || 0;
        row.getCell(16).value = `${
          data.nodes[i - 1].voucher_code ? data.nodes[i - 1].voucher_code : "-"
        }  ${data.nodes[i - 1].discount_type === "percentage" ? "(%)" : ""}`;
        row.getCell(17).value = data.nodes[i - 1].nominal_voucher;
        row.getCell(18).value = data.nodes[i - 1].grand_total;
        row.getCell(19).value = data.nodes[i - 1].payment_detail?.pay;
        row.getCell(20).value = data.nodes[i - 1].payment_detail?.total_cash;
        row.getCell(21).value = data.nodes[i - 1].payment_detail?.total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(12).value = totalSales.sum_sales_point;
      rowFooter.getCell(13).value = totalSales.sum_sales_total;
      rowFooter.getCell(14).value = totalSales.sum_sales_delivery_fee;
      rowFooter.getCell(15).value = totalSales.sum_sales_tax;
      rowFooter.getCell(17).value = totalSales.sum_sales_nominal_voucher;
      rowFooter.getCell(18).value = totalSales.sum_sales_grand_total;
      rowFooter.getCell(19).value = totalSales.sum_sales_total_payment;
      rowFooter.getCell(20).value = totalSales.sum_sales_total_cash;
      rowFooter.getCell(21).value = totalSales.sum_sales_total_change;
      rowFooter.getCell(12).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(13).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(14).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(15).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(17).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(18).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(19).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(20).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(21).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "customer") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;
      ws.getColumn(13).width = 30;
      ws.getColumn(14).width = 30;
      ws.getColumn(15).width = 30;
      ws.getColumn(16).width = 30;
      ws.getColumn(17).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 17; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Member Code";
      rowHeader.getCell(2).value = "Member Name";
      rowHeader.getCell(3).value = "Date";
      rowHeader.getCell(4).value = "Email";
      rowHeader.getCell(5).value = "Phone";
      rowHeader.getCell(6).value = "Store Code";
      rowHeader.getCell(7).value = "Store Name";
      rowHeader.getCell(8).value = "Store Address";
      rowHeader.getCell(9).value = "Sales Count";
      rowHeader.getCell(10).value = "Total";
      rowHeader.getCell(11).value = "Tax";
      rowHeader.getCell(12).value = "Shipping Cost";
      rowHeader.getCell(13).value = "Voucher";
      rowHeader.getCell(14).value = "Grand Total";
      rowHeader.getCell(15).value = "Grand Total";
      rowHeader.getCell(16).value = "Grand Total";
      rowHeader.getCell(17).value = "Grand Total";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].code;
        row.getCell(2).value = data.nodes[i - 1].name;
        row.getCell(3).value = formatedDate(data.nodes[i - 1].created_at);
        row.getCell(4).value = data.nodes[i - 1].email;
        row.getCell(5).value = data.nodes[i - 1].phone_number;
        row.getCell(6).value = data.nodes[i - 1].store.code;
        row.getCell(7).value = data.nodes[i - 1].store.name;
        row.getCell(8).value = data.nodes[i - 1].store.address;
        row.getCell(9).value = data.nodes[i - 1].sales_count;
        row.getCell(10).value = data.nodes[i - 1].sales_sum_total;
        row.getCell(11).value = data.nodes[i - 1].sales_sum_tax;
        row.getCell(12).value = data.nodes[i - 1].sales_sum_delivery_fee;
        row.getCell(13).value =
          data.nodes[i - 1].sales_sum_nominal_voucher || 0;
        row.getCell(14).value = data.nodes[i - 1].sales_sum_grand_total;
        row.getCell(15).value = data.nodes[i - 1].payment_header_sum_total_pay;
        row.getCell(16).value = data.nodes[i - 1].payment_header_sum_total_cash;
        row.getCell(17).value =
          data.nodes[i - 1].payment_header_sum_total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(10).value = totalSales.sum_sales_total;
      rowFooter.getCell(11).value = totalSales.sum_sales_tax;
      rowFooter.getCell(12).value = totalSales.sum_sales_delivery_fee;

      rowFooter.getCell(13).value = totalSales.sum_sales_nominal_voucher;
      rowFooter.getCell(14).value = totalSales.sum_sales_grand_total;
      rowFooter.getCell(15).value = totalSales.sum_sales_total_payment;

      rowFooter.getCell(16).value = totalSales.sum_sales_total_cash;
      rowFooter.getCell(17).value = totalSales.sum_sales_total_change;

      rowFooter.getCell(10).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(11).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(12).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(13).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(14).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(15).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(16).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(17).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sales - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "product") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;
      ws.getColumn(13).width = 30;
      ws.getColumn(14).width = 30;
      ws.getColumn(15).width = 30;
      ws.getColumn(16).width = 30;
      ws.getColumn(17).width = 30;
      ws.getColumn(18).width = 30;
      ws.getColumn(19).width = 30;
      ws.getColumn(20).width = 30;
      ws.getColumn(21).width = 30;
      ws.getColumn(22).width = 30;
      ws.getColumn(23).width = 30;
      ws.getColumn(24).width = 30;
      ws.getColumn(25).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 25; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction No";
      rowHeader.getCell(2).value = "Date";
      rowHeader.getCell(3).value = "Member Code";
      rowHeader.getCell(4).value = "Member Name";
      rowHeader.getCell(5).value = "Cashier";
      rowHeader.getCell(6).value = "Store Code";
      rowHeader.getCell(7).value = "Store Name";
      rowHeader.getCell(8).value = "Store Address";
      rowHeader.getCell(9).value = "Payment Method";
      rowHeader.getCell(10).value = "Source";
      rowHeader.getCell(11).value = "Delivery Type";
      rowHeader.getCell(12).value = "Product Division";
      rowHeader.getCell(13).value = "Category Name";
      rowHeader.getCell(14).value = "SubCategory Name";
      rowHeader.getCell(15).value = "Brand";
      rowHeader.getCell(16).value = "Product Code";
      rowHeader.getCell(17).value = "Product Name";
      rowHeader.getCell(18).value = "Price";
      rowHeader.getCell(19).value = "Quantity";
      rowHeader.getCell(20).value = "Unit";
      rowHeader.getCell(21).value = "Total";
      rowHeader.getCell(22).value = "Discount";
      rowHeader.getCell(23).value = "Grand Total";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].sales_header.transaction_no;
        row.getCell(2).value = formatedDateTime(
          data.nodes[i - 1].sales_header.transaction_date
        );
        row.getCell(3).value = data.nodes[i - 1].sales_header.person?.code;
        row.getCell(4).value = data.nodes[i - 1].sales_header.person?.name;
        row.getCell(5).value =
          data.nodes[i - 1].sales_header.created_person?.person?.name;
        row.getCell(6).value = data.nodes[i - 1].sales_header.store.code;
        row.getCell(7).value = data.nodes[i - 1].sales_header.store.name;
        row.getCell(8).value = data.nodes[i - 1].sales_header.store.address;
        row.getCell(9).value =
          data.nodes[i - 1].sales_header.payment_method.name;
        row.getCell(10).value = data.nodes[i - 1].sales_header?.source;
        row.getCell(11).value =
          data.nodes[i - 1].sales_header?.delivery_type.toUpperCase();
        row.getCell(12).value =
          data.nodes[i - 1].product?.product_division?.name;

        row.getCell(13).value =
          data.nodes[i - 1].product?.product_category?.name;

        row.getCell(14).value =
          data.nodes[i - 1].product?.product_subcategory?.name;
        row.getCell(15).value = data.nodes[i - 1].product?.product_brand?.name;
        row.getCell(16).value = data.nodes[i - 1].product?.code;
        row.getCell(17).value = data.nodes[i - 1].product.name;
        row.getCell(18).value = data.nodes[i - 1].price;
        row.getCell(19).value = data.nodes[i - 1].quantity;
        row.getCell(20).value = data.nodes[i - 1].product.unit.name;
        row.getCell(21).value = data.nodes[i - 1].total;
        row.getCell(22).value = data.nodes[i - 1].discount;
        row.getCell(23).value = data.nodes[i - 1].subtotal;

        no++;
      }
      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(19).value = totalSales.sum_sales_quantity;
      rowFooter.getCell(21).value = totalSales.sum_sales_total;

      rowFooter.getCell(22).value = totalSales.sum_sales_discount;
      rowFooter.getCell(23).value = totalSales.sum_sales_subtotal;

      rowFooter.getCell(19).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(21).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(22).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(23).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "payment_method") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 10; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction Date";
      rowHeader.getCell(2).value = "Store Name";
      rowHeader.getCell(3).value = "Store Address";
      rowHeader.getCell(4).value = "Payment Method";
      rowHeader.getCell(5).value = "Total Nota";
      rowHeader.getCell(6).value = "Total Product";
      rowHeader.getCell(7).value = "Total Sales";
      rowHeader.getCell(8).value = "Total Payment";
      rowHeader.getCell(9).value = "Total Cash";
      rowHeader.getCell(10).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = formatedDate(data.nodes[i - 1].transaction_date);
        row.getCell(2).value = data.nodes[i - 1].store;
        row.getCell(3).value = data.nodes[i - 1].address;
        row.getCell(4).value = data.nodes[i - 1].name;
        row.getCell(5).value = data.nodes[i - 1].total_nota;
        row.getCell(6).value = data.nodes[i - 1].total_product;
        row.getCell(7).value = data.nodes[i - 1].total_sales;
        row.getCell(8).value = data.nodes[i - 1].total_payment;
        row.getCell(9).value = data.nodes[i - 1].total_cash;
        row.getCell(10).value = data.nodes[i - 1].total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);
      rowFooter.getCell(5).value = totalSales.total_nota;
      rowFooter.getCell(6).value = totalSales.total_product;
      rowFooter.getCell(7).value = totalSales.total_sales;
      rowFooter.getCell(8).value = totalSales.total_payment;
      rowFooter.getCell(9).value = totalSales.total_cash;
      rowFooter.getCell(10).value = totalSales.total_change;
      rowFooter.getCell(5).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(6).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(7).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(8).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(9).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(10).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "cashier") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 10; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction Date";
      rowHeader.getCell(2).value = "Store Name";
      rowHeader.getCell(3).value = "Store Address";
      rowHeader.getCell(4).value = "Cashier";
      rowHeader.getCell(5).value = "Total Nota";
      rowHeader.getCell(6).value = "Total Product";
      rowHeader.getCell(7).value = "Total Sales";
      rowHeader.getCell(8).value = "Total Payment";
      rowHeader.getCell(9).value = "Total Cash";
      rowHeader.getCell(10).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = formatedDate(data.nodes[i - 1].transaction_date);
        row.getCell(2).value = data.nodes[i - 1].store;
        row.getCell(3).value = data.nodes[i - 1].address;
        row.getCell(4).value = data.nodes[i - 1].name;
        row.getCell(5).value = data.nodes[i - 1].total_nota;
        row.getCell(6).value = data.nodes[i - 1].total_product;
        row.getCell(7).value = data.nodes[i - 1].total_sales;
        row.getCell(8).value = data.nodes[i - 1].total_payment;
        row.getCell(9).value = data.nodes[i - 1].total_cash;
        row.getCell(10).value = data.nodes[i - 1].total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);
      rowFooter.getCell(5).value = totalSales.total_nota;
      rowFooter.getCell(6).value = totalSales.total_product;
      rowFooter.getCell(7).value = totalSales.total_sales;
      rowFooter.getCell(8).value = totalSales.total_payment;
      rowFooter.getCell(9).value = totalSales.total_cash;
      rowFooter.getCell(10).value = totalSales.total_change;

      rowFooter.getCell(5).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(6).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(7).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(8).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(9).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(10).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "total") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 9; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction Date";
      rowHeader.getCell(2).value = "Store Name";
      rowHeader.getCell(3).value = "Store Address";
      rowHeader.getCell(4).value = "Total Nota";
      rowHeader.getCell(5).value = "Total Product";
      rowHeader.getCell(6).value = "Total Sales";
      rowHeader.getCell(7).value = "Total Payment";
      rowHeader.getCell(8).value = "Total Cash";
      rowHeader.getCell(9).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = formatedDate(data.nodes[i - 1].transaction_date);
        row.getCell(2).value = data.nodes[i - 1].store;
        row.getCell(3).value = data.nodes[i - 1].address;
        row.getCell(4).value = data.nodes[i - 1].total_nota;
        row.getCell(5).value = data.nodes[i - 1].total_product;
        row.getCell(6).value = data.nodes[i - 1].total_sales;
        row.getCell(7).value = data.nodes[i - 1].total_payment;
        row.getCell(8).value = data.nodes[i - 1].total_cash;
        row.getCell(9).value = data.nodes[i - 1].total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);
      rowFooter.getCell(4).value = totalSales.total_nota;
      rowFooter.getCell(5).value = totalSales.total_product;
      rowFooter.getCell(6).value = totalSales.total_sales;
      rowFooter.getCell(7).value = totalSales.total_payment;
      rowFooter.getCell(8).value = totalSales.total_cash;
      rowFooter.getCell(9).value = totalSales.total_change;

      rowFooter.getCell(4).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(5).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(6).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(7).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(8).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(9).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    }
  };
  setTimeout(() => {
    setisLoading(false);
  }, 700);
  useEffect(() => {
    if (WW < 770) {
      setSizesColumn("200px");
    } else {
      setSizesColumn("200px");
    }
  }, [currentPage]);
  useEffect(() => {
    setnoTransaction("");
    if (type.value === "trans") {
      setcustomTheme(customTrans);
    } else if (type.value === "product") {
      setcustomTheme(customProduct);
    } else if (type.value === "customer") {
      setcustomTheme(costumCostumer);
    } else if (type.value === "total") {
      setcustomTheme(customTotal);
    } else {
      setcustomTheme(customPaymentMethod);
    }
  }, [type]);

  return (
    <Box bg="white" p="5" rounded={"md"}>
      <Box mb={5}>
        <Text fontSize={[14, 16, 18]} fontFamily={"Semibold"} color="black">
          Sales
        </Text>
      </Box>
      <Wrap spacing={8}>
        <WrapItem zIndex={6}>
          <Stack px="2">
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              Type
            </Text>
            <Box>
              <Select
                value={type}
                onChange={handleType}
                options={dataType.map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
              />
            </Box>
          </Stack>
        </WrapItem>

        <WrapItem zIndex={5}>
          <Stack px="2">
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              Start Date
            </Text>

            <HStack flex={1} borderWidth={1} p={2} borderRadius={5}>
              <Box flex={1}>
                <ReactDatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={filter.startDate}
                  onChange={(date) =>
                    setfilter((prevState) => ({
                      ...prevState,
                      startDate: new Date(date),
                    }))
                  }
                />
              </Box>
              <Calendar color="black" fontSize={FONTSIZES.md} />
            </HStack>
          </Stack>
        </WrapItem>
        <WrapItem zIndex={2}>
          <Stack>
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              End Date
            </Text>
            <HStack flex={1} borderWidth={1} p={2} borderRadius={5}>
              <ReactDatePicker
                dateFormat="dd-MM-yyyy"
                selected={filter.endDate}
                onChange={(date) =>
                  setfilter((prevState) => ({
                    ...prevState,
                    endDate: new Date(date),
                  }))
                }
              />
              <Calendar color="black" fontSize={FONTSIZES.md} />
            </HStack>
          </Stack>
        </WrapItem>

        <WrapItem>
          <Stack>
            <Text fontSize={FONTSIZES.md} color="white" fontFamily={"SemiBold"}>
              Period
            </Text>
            <Button
              isDisabled={!type}
              w="20"
              colorScheme="teal"
              onClick={fetchData}
            >
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Filter
              </Text>
            </Button>
          </Stack>
        </WrapItem>

        <WrapItem>
          <Stack display={{ base: "flex" }}>
            <Text color="white" fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              Period
            </Text>
            <Button colorScheme="teal" onClick={onOpen}>
              <HStack>
                <ICFilter size="20" color="white" />
                <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                  Advance Filter
                </Text>
              </HStack>
            </Button>
            <Drawer
              isOpen={isOpen}
              size="xl"
              placement="right"
              onClose={onClose}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  <Text fontFamily={"Semibold"}>Advance Filter</Text>
                </DrawerHeader>
                <DrawerBody>
                  <Stack spacing="24px">
                    <HStack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Type
                        </Text>
                        <Box>
                          <Select
                            value={type}
                            onChange={handleType}
                            options={dataType.map((item) => ({
                              value: item.id,
                              label: item.title,
                            }))}
                          />
                        </Box>
                      </Stack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Start Date
                        </Text>

                        <HStack borderWidth={1} p={2} borderRadius={5}>
                          <ReactDatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={filter.startDate}
                            onChange={(date) =>
                              setfilter((prevState) => ({
                                ...prevState,
                                startDate: new Date(date),
                              }))
                            }
                          />
                          <Calendar color="black" />
                        </HStack>
                      </Stack>

                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          End Date
                        </Text>

                        <HStack borderWidth={1} p={2} borderRadius={5}>
                          <ReactDatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={filter.endDate}
                            onChange={(date) =>
                              setfilter((prevState) => ({
                                ...prevState,
                                endDate: new Date(date),
                              }))
                            }
                          />
                          <Calendar color="black" />
                        </HStack>
                      </Stack>
                    </HStack>
                    {type.value !== "customer" && (
                      <Box>
                        <Stack>
                          <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                            Search
                          </Text>
                          <Box>
                            <Input
                              value={noTransaction}
                              onChange={(e) => setnoTransaction(e.target.value)}
                              fontFamily={"Regular"}
                              id="username"
                              placeholder="Search Transaction No"
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}

                    <Box>
                      <Stack>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Store
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedStore}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedStore(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsStore}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                    <HStack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Cashier
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedCashier}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedCashier(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsCashier}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Customer
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedCustomer}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedCustomer(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsCustomer}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                    </HStack>
                    <HStack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Product
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedProduct}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedProduct(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsProduct}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Category
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedCategorie}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedCategorie(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsCategorie}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                    </HStack>
                    <HStack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Sub Category
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedSubCategory}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedSubCategory(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsSubCategory}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                      <Stack flex={1}>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Payment Method
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedPayment}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedPayment(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsPayment}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                    </HStack>
                  </Stack>
                </DrawerBody>
                <DrawerFooter borderTopWidth="1px">
                  <Button variant="outline" mr={3} onClick={onClose}>
                    <Text fontFamily={"Semibold"}>Cancel</Text>
                  </Button>
                  <Button
                    isDisabled={!type}
                    colorScheme="teal"
                    onClick={fetchData}
                  >
                    <Text fontFamily={"Semibold"}>Submit</Text>
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Stack>
        </WrapItem>

        {data.nodes?.length > 0 && (
          <WrapItem justifyContent={"flex-end"} flex={1}>
            <HStack>
              <Stack display={{ base: "flex" }}>
                <Text
                  fontSize={FONTSIZES.md}
                  color="white"
                  fontFamily={"SemiBold"}
                >
                  Period
                </Text>

                <Button w="30" colorScheme="teal" onClick={exportToExcel}>
                  <HStack>
                    <MyIcon name={"file-down"} size={18} color="white" />
                    <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                      Export
                    </Text>
                  </HStack>
                </Button>
              </Stack>
            </HStack>
          </WrapItem>
        )}
      </Wrap>
      <br />
      {type.value === "trans" ? (
        <Trans
          isLoading={isLoading}
          data={data}
          theme={theme}
          totalSales={totalSales}
        />
      ) : type.value === "product" ? (
        <Product
          isLoading={isLoading}
          data={data}
          theme={theme}
          totalSales={totalSales}
        />
      ) : type.value === "payment_method" ? (
        <Payment
          isLoading={isLoading}
          data={data}
          theme={theme}
          totalSales={totalSales}
        />
      ) : type.value === "cashier" ? (
        <Cashier
          isLoading={isLoading}
          data={data}
          theme={theme}
          totalSales={totalSales}
        />
      ) : type.value === "total" ? (
        <Total
          isLoading={isLoading}
          data={data}
          theme={theme}
          totalSales={totalSales}
        />
      ) : (
        <Customer
          isLoading={isLoading}
          data={data}
          theme={theme}
          totalSales={totalSales}
        />
      )}
    </Box>
  );
};

export default ReportSale;
